// import { createClient } from '@supabase/supabase-js';
import { init, addMessages } from 'svelte-i18n';
import * as Sentry from '@sentry/sveltekit';
import { browser, dev } from '$app/environment';
import register from './register';

export const defaultLocale = 'ko';
export const availableLocales = ['ko', 'en', 'ja'];

type Messages = {
    [key: string]: any;
};
export type KeyType = 'origin' | 'fullkey' | 'lastkey';

let supabase;
let showKey: KeyType;
function transformMessagesToKeys(messages: Messages): Messages {
    function transform(obj: Messages, prefix = ''): Messages {
        const result: Messages = {};
        for (const key in obj) {
            const fullKey = prefix ? `${prefix}.${key}` : key;
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                result[key] = transform(obj[key], fullKey);
            } else {
                result[key] = fullKey;
            }
        }
        return result;
    }

    return transform(messages);
}
function transformMessagesToLastKeys(messages: Messages): Messages {
    function transform(obj: Messages): Messages {
        const result: Messages = {};
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                result[key] = transform(obj[key]);
            } else {
                result[key] = key;
            }
        }
        return result;
    }

    return transform(messages);
}

export function setShowI18nKey(value: KeyType) {
    showKey = value;
}
export async function applySheetSyncI18n() {
    if (supabase) {
        try {
            const { data: FEi18n, error } = await supabase.from('FEi18n').select('*');
            FEi18n.forEach(({ lang, json }) => {
                if (showKey === 'fullkey') {
                    addMessages(lang, transformMessagesToKeys(json));
                } else if (showKey === 'lastkey') {
                    addMessages(lang, transformMessagesToLastKeys(json));
                } else {
                    addMessages(lang, json);
                }
            });
        } catch (err) {
            console.error('syncI18n error:', err);
            Sentry.captureException(err);
        }
    }
}

export async function subscribeSheetI18n() {
    supabase
        .channel('all')
        .on('postgres_changes', { event: '*', schema: 'public', table: 'FEi18n' }, payload => {
            if (payload.new && payload.new['lang'] && payload.new['json']) {
                addMessages(payload.new['lang'], payload.new['json']);
                if (showKey === 'fullkey') {
                    addMessages(payload.new['lang'], transformMessagesToKeys(payload.new['json']));
                } else if (showKey === 'lastkey') {
                    addMessages(payload.new['lang'], transformMessagesToLastKeys(payload.new['json']));
                } else {
                    addMessages(payload.new['lang'], payload.new['json']);
                }
            }
        })
        .subscribe();
}

export async function unsubscribeSheetI18n() {
    supabase.removeAllChannels();
}

// try {
//     if ((dev || __IS_DEV_SERVER_ENV__) && browser && process.env.SUPABASE_URL && process.env.SUPABASE_ANON_KEY) {
//         supabase = createClient(process.env.SUPABASE_URL, process.env.SUPABASE_ANON_KEY);
//     }
// } catch (err) {
//     console.error('subsI18n error:', err);
//     Sentry.captureException(err);
// }

// 환경변수 별 locale 등록
register();

init({
    fallbackLocale: defaultLocale,
    initialLocale: defaultLocale,
});
